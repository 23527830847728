<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import ServicesList from "./partials/ServicesList.vue";

export default {
    components: {
        Breadcrumbs,
        ServicesList
    },
    props: {},
    data() {
        return {
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Linhas", path: "lines.index" },
                { name: "Serviços", path: "lines.bills" },
            ],
            linesServices: [
                {
                    id: 9,
                    name: "Processo de Horário",
                    description: "Solicitação para alterar os horários das linhas regulares.",
                    form: "linesForm",
                    alias: "processo-horario",
                    subitens: []
                },
                {
                    id: 10,
                    name: "Processo de Alteração de Linha",
                    description: "Alterar alguma característica da linha, quando necessário.",
                    form: "linesForm",
                    alias: "alteracao-linha",
                    subitens: [
                        { id: 22, name: "Fusão de Linhas", label: "Tipo", alias: 'fusao-linhas', additionalFields: [] },
                        { id: 13, name: "Cancelamento de Linhas", label: "Tipo", alias: 'cancelamento-linha', additionalFields: [] },
                    ]
                },
                {
                    id: 11,
                    name: "Reclassificação da Linha",
                    description: "Alterar a linha dae rodoviária para urbana ou de urbana para rodoviária, se necessário.",
                    form: "linesForm",
                    alias: "reclassificacao-linha",
                    subitens: [
                        {
                            id: 24, name: "Por Mercado", label: "Tipo", alias: 'reclassificacao-mercado', additionalFields: [
                                { type: 'select', label: 'Mercado', name: 'mercado', options: [{ id: 1, name: 'Urbano'}, { id: 2, name: 'Rodoviário'}] }
                            ]
                        },
                        {
                            id: 25, name: "Por Piso de Rodagem", label: "Tipo", alias: 'reclassificacao-piso', additionalFields: [
                                { type: 'number', label: 'KM Asfalto', name: 'km-asfalto' },
                                { type: 'number', label: 'KM Terra', name: 'km-terra' },
                            ]
                        },
                    ]
                },
                {
                    id: 12,
                    name: "Alteração de Itinerário",
                    description: "Solicitar, quando identificado, a mudança do destino de uma seção da linha.",
                    form: "linesForm",
                    alias: "alteracao-itinerario",
                    subitens: [
                        { id: 12, name: "Alteração de Itinerário", label: "Tipo", alias: 'alteracao-itinerario', additionalFields: [] },
                        { id: 27, name: "Implantação de Seção", label: "Tipo", alias: 'implantacao-secao', additionalFields: [] },
                        { id: 28, name: "Cancelamento de Seção", label: "Tipo", alias: 'cancelamento-secao', additionalFields: [] },
                    ]
                },
            ],
        };
    },
    methods: {
        toggleList(type) {
            // define refs como um array de keys
            Object.keys(this.$refs).forEach((key) => {
                // verifica se a key contém o tipo selecionado
                if (key.includes(type)) {
                    // se list, remove a classe hidden
                    if (key.includes("list")) {
                        this.$refs[key].classList.remove("hidden");
                    } else {
                        // se button, adiciona a classe selected-button e
                        this.$refs[key].classList.add("selected-button");
                        // remove a classe non-selected-button
                        this.$refs[key].classList.remove("non-selected-button");
                    }
                    // se não contém o tipo selecionado
                } else {
                    // se list, adiciona a classe hidden
                    if (key.includes("list")) {
                        this.$refs[key].classList.add("hidden");
                    } else {
                        // se button, remove a classe selected-button e
                        this.$refs[key].classList.remove("selected-button");
                        // adiciona a classe non-selected-button
                        this.$refs[key].classList.add("non-selected-button");
                    }
                }
            });
        },
    },
    mounted() { },
};
</script>
<style>
button:focus {
    outline: 0 !important;
}

.selected-button {
    color: white;
    background-image: linear-gradient(to right, #397dbd, #129dee);
}

.non-selected-button {
    opacity: 0.5;
    background-image: linear-gradient(to right, #8dccf0, #98d8fd);
}
</style>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-2" :crumbs="crumbs" />
            <div class="flex flex-wrap justify-between align-baseline border-b-2 border-blue-base mb-6">
                <h1 class="text-blue-base uppercase pt-5">Serviços</h1>
            </div>
            <div class="grid grid-cols-3 gap-2 text-lg mb-1">
                <button class="col-span-3 selected-button text-center content-center h-16 uppercase shadow-md"
                    @click="toggleList('LinesServices')" ref="buttonLinesServices">
                    Linhas
                </button>
            </div>
            <div ref="listLinesServices" class="shadow-md bg-gray-100 p-2">
                <ServicesList :services="linesServices" />
            </div>
        </div>
    </div>
</template>
