<template>
    <div v-click-outside="close" :class="{
        'dropdown-is-open': isOpen
    }" class="search-select-wrapper">
        <label v-if="label" :class="labelClass" class="select-label" @click="toggle()" v-html="label" />
        <div class="search-select-input-wrapper">
            <template v-if="optionalPlaceholderIcon">
                <button ref="selectinput" :class="[{ 'search-select-placeholder': !selectedOptionValue }, fieldClass]"
                    :disabled="disabled" class="search-select flex items-center" type="button" @click.prevent="toggle()"
                    @keydown.space="toggle()" @keydown.esc="close()">
                    <img class="h-3 mr-2" :src="require(`@/core/assets/images/${optionalPlaceholderIcon}`)">
                    <span v-if="selectedOptionValue" v-html="selectedOptionValue"></span>
                    <span v-else>{{ placeholder }}</span>
                </button>
            </template>
            <template v-else>
                <button ref="selectinput" :class="[
                    {
                        'search-select-placeholder': !selectedOptionValue
                    },
                    fieldClass
                ]" :disabled="disabled" class="search-select" type="button" @click.prevent="toggle()"
                    @keydown.space="toggle()" @keydown.esc="close()"
                    v-html="selectedOptionValue ? selectedOptionValue : placeholder" />
            </template>

            <div class="search-select-carret">
                <img v-if="optionalCarretIcon" class="h-3" :src="require(`@/core/assets/images/${optionalCarretIcon}`)">
                <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
            </div>
            <transition name="dropdown-transition">
                <div v-show="isOpen" ref="selectdropdown" class="search-select-dropdown">
                    <input ref="search" v-model="query" class="search-select-dropdown-input" @keydown.esc="close()"
                        @keydown.down="highlightNext()" @keydown.up="highlightPrevious()"
                        @keydown.enter.prevent="selectHighlighted()" @keydown.tab.prevent @input="
                            highlightedIndex = 0;
                        getRemoteOptions();
                        " />
                    <ul v-if="!isLoading" ref="options" class="search-select-dropdown-options">
                        <li v-for="(option, index) in filteredOptionsList" :key="option[optionKey]" :class="{
                            'search-select-dropdown-option-highlighted':
                                index === highlightedIndex
                        }" class="search-select-dropdown-option" @click="select(option)">
                            <slot :option="option" name="option">
                                {{ optionValue(option) }}
                            </slot>
                        </li>
                    </ul>
                    <div v-if="!filteredOptionsList.length && !isLoading" class="search-select-empty">
                        <slot name="no-results">
                            <span>{{ digitsBeforeSearch > 0 ? `Digite pelo menos ${digitsBeforeSearch} ${type} para fazer a
                                pesquisa.` : 'Nenhum resultado.' }}</span>
                        </slot>
                    </div>
                    <div v-if="isLoading" class="search-select-empty">
                        <slot name="loading">
                            <span>Aguarde...</span>
                        </slot>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Fuse from "fuse.js";
import Popper from "popper.js";
import _findKey from "lodash/findKey";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
export default {
    props: {
        selected: {
            default: null
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        optionsUrl: {
            type: String,
            default: null
        },
        responseDataPath: {
            type: String,
            default: null
        },
        lazyLoad: {
            type: Boolean,
            default: false
        },
        optionKey: {
            type: String,
            required: true
        },
        optionValue: {
            default: () => {
                return item => {
                    return item.name;
                };
            }
        },
        searchKeys: {
            type: Array,
            required: true,
            default: () => []
        },
        searchRemote: {
            type: Boolean,
            default: false
        },
        noQueryParam: {
            type: Boolean,
            default: false
        },
        allowEmpty: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            required: false,
            default: "&nbsp;"
        },
        autocomplete: {
            type: String,
            required: false,
            default: null
        },
        fieldClass: {
            type: String,
            required: false,
            default: null
        },
        labelClass: {
            type: String,
            required: false,
            default: ""
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        selectFirst: {
            type: Boolean,
            required: false,
            default: false
        },
        method: {
            type: String,
            required: false,
            default: "get"
        },
        digitsBeforeSearch: {
            type: Number,
            required: false,
            default: 0
        },
        optionalPlaceholderIcon: {
            type: String,
            required: false,
            default: null
        },
        optionalCarretIcon: {
            type: String,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: 'letras'
        }
    },
    data() {
        return {
            isOpen: false,
            query: null,
            highlightedIndex: 0,
            remoteOptions: [],
            isLoading: false
        };
    },
    computed: {
        optionsList() {
            return this.remoteOptions.length
                ? this.remoteOptions
                : this.options;
        },
        filteredOptionsList() {
            if (!this.query) {
                return this.optionsList;
            }
            if (this.searchRemote) {
                return this.remoteOptions;
            }
            let fuse = new Fuse(this.optionsList, {
                threshold: 0.2,
                keys: this.searchKeys
            });
            return fuse.search(this.query);
        },
        selectedOptionValue() {
            return !_isEmpty(this.selected)
                ? this.optionValue(this.selected)
                : "";
        },
        selectedOptionIndex() {
            let $this = this;
            return _findKey(this.optionsList, function (o) {
                return o[$this.optionKey] == $this.selected[$this.optionKey];
            });
        }
    },
    watch: {
        filteredOptionsList() {
            if (this.popper !== undefined) {
                this.popper.scheduleUpdate();
            }
        }
    },
    mounted() {
        if (!this.optionsUrl) return;
        if (!this.lazyLoad && this.digitsBeforeSearch == 0) this.getRemoteOptions();
    },
    beforeDestroy() {
        if (this.popper !== undefined) {
            this.popper.destroy();
        }
    },
    methods: {
        select(option) {
            /* Return selected option */
            this.$emit("update:selected", option);
            this.$emit("changed", option);
            this.close();
        },
        reset() {
            this.query = "";
        },
        selectHighlighted() {
            if (!this.filteredOptionsList.length) {
                return;
            }
            this.select(this.filteredOptionsList[this.highlightedIndex]);
        },
        setupPopper() {
            if (this.popper === undefined) {
                this.popper = new Popper(
                    this.$refs.selectinput,
                    this.$refs.selectdropdown,
                    {
                        placement: "bottom"
                    }
                );
            } else {
                this.popper.scheduleUpdate();
            }
        },
        open() {
            if (this.isOpen) return;
            if (this.lazyLoad) this.getRemoteOptions();
            this.isOpen = true;
            this.$nextTick(() => {
                this.setupPopper();
                this.$refs.search.focus();
                this.highlightedIndex = this.selectedOptionIndex
                    ? Number(this.selectedOptionIndex)
                    : 0;
                this.scrollToIndex(this.highlightedIndex);
            });
        },
        close() {
            if (!this.isOpen) return;
            this.isOpen = false;
            this.$nextTick(() => {
                this.$refs.selectinput.focus();
                this.reset();
            });
        },
        toggle() {
            if (this.disabled || this.readonly) return;
            this.isOpen ? this.close() : this.open();
        },
        scrollToIndex(index) {
            if (
                typeof this.$refs.options == "undefined" ||
                typeof this.$refs.options.children[index] == "undefined"
            )
                return;
            this.$refs.options.children[index].scrollIntoView({
                block: "nearest"
            });
        },
        highlightNext() {
            this.highlightedIndex =
                this.highlightedIndex == this.filteredOptionsList.length - 1
                    ? this.filteredOptionsList.length - 1
                    : this.highlightedIndex + 1;

            this.scrollToIndex(this.highlightedIndex);
        },
        highlightPrevious() {
            this.highlightedIndex =
                this.highlightedIndex == 0 ? 0 : this.highlightedIndex - 1;
            this.scrollToIndex(this.highlightedIndex);
        },
        getRemoteOptions() {
            if ((!this.searchRemote && this.optionsList.length) || !this.optionsUrl) {
                return;
            }
            let searchQuery = "";
            if (this.query) {
                if (this.optionsUrl.includes("?")) {
                    searchQuery = "&" + this.searchKeys[0] + "=" + this.query;
                } else if (_isEmpty(this.searchKeys[0])) {
                    searchQuery = this.query;
                } else {
                    searchQuery = "?" + this.searchKeys[0] + "=" + this.query;
                }
            }
            let $this = this;
            this.isLoading = true;
            if (this.digitsBeforeSearch > 0 && this.query == null) {
                this.isLoading = false;
                return;
            }
            if (this.digitsBeforeSearch > 0 && this.query.length < this.digitsBeforeSearch) {
                this.isLoading = false;
                return;
            }
            axios({
                method: this.method || "get",
                url:
                    this.optionsUrl +
                    (this.noQueryParam ? this.query : searchQuery),
                responseType: "json"
            }).then(function (response) {
                $this.remoteOptions = $this.responseDataPath
                    ? _get(response.data, $this.responseDataPath)
                    : response.data;
                if (
                    $this.selectFirst &&
                    !$this.selectedOptionValue &&
                    !$this.lazyLoad
                ) {
                    $this.select($this.remoteOptions[0]);
                }
                $this.$emit("remoteSuccess", response);
            }).catch(function (error) {
                $this.$emit("remoteError", error);
            }).then(function () {
                $this.isLoading = false;
                if ($this.popper) {
                    $this.popper.scheduleUpdate();
                }
            });
        }
    }
};
</script>
