<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";

export default {
    components: {
        Breadcrumbs
    },
    data() {
        return {
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Gerenciamento de Linhas", path: "lines.index" },
                { name: "Linhas Regulares", path: "lines.services" },
                { name: "Nova Linha", path: "lines.service.criar" },
            ],
        }
    },
    methods: {

    },
    mounted: function () {
    }
}
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-2" :crumbs="crumbs" />
            <div class="flex flex-wrap justify-between align-baseline border-b-2 border-blue-base mb-2">
                <h1 class="text-blue-base uppercase pt-5 mb-2">Informações da Linha</h1>
            </div>
        </div>

        <div class="p-2">
            <div class="px-4">
                <div class=" px-4">
                    <div class="px-4 bg-white shadow-md pt-3 pb-10 text-sm">
                        <form @submit.prevent="submitForm" class="grid grid-cols-6 gap-4">
                            <div class="mb-3">
                                <label class="form-label" for="linha">Linha:</label>
                                <input type="text" class="form-input" id="linha" v-model="linha" required>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="linha">Extensão:</label>
                                <input type="text" class="form-input" id="extensao" v-model="extensao" required>
                            </div>
                            <div class="mb-3 col-span-4">
                                <label class="form-label" for="transportadora">Transportadora:</label>
                                <input type="text" class="form-input" id="transportadora" v-model="transportadora"
                                    required>
                            </div>

                            <div class="mb-3 col-span-3">
                                <label class="form-label" for="origem">Origem:</label>
                                <input type="text" class="form-input" id="origem" v-model="origem" required>
                            </div>
                            <div class="mb-3 col-span-3">
                                <label class="form-label" for="destino">Destino:</label>
                                <input type="text" class="form-input" id="destino" v-model="destino" required>
                            </div>

                            <div class="mb-3 col-span-6">
                                <label class="form-label" for="descricao-itinerario">Descriação do Itinerário:</label>
                                <textarea class="form-input" id="descricao-itinerario" v-model="descricaoItinetario"
                                    required />
                            </div>

                            <div class="mb-3 col-span-2">
                                <label class="form-label" for="tipo-viagem">Tipo de Viagem:</label>
                                <input type="text" class="form-input" id="tipo-viagem" v-model="tipoViagem" required>
                            </div>
                            <div class="mb-3 col-span-2 col-start-3 col-end-5">
                                <label class="form-label" for="operacao">Operação:</label>
                                <div class="flex h-10">
                                    <div class="flex w-1/2">
                                        <input class="h-full w-6 mr-1" v-model="operacao" name="operacao" value="rodoviaria" id="operaca-rodoviaria" type="radio" />
                                        <label class="form-label flex items-center mr-5 mb-0" for="operacao-rodoviaria">Rodoviária</label>
                                    </div>
                                    <div class="flex w-1/2">
                                        <input class="h-full w-6 mr-1" v-model="operacao" name="operacao" value="urbana" id="operaca-urbana" type="radio" />
                                        <label class="form-label flex items-center mr-5 mb-0" for="operacao-urbana">Urbana</label>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3 col-span-2 col-start-1 col-end-3">
                                <label class="form-label" for="coeficiente-tarifario">Coeficiente de Multiplicação da
                                    Tarifa:</label>
                                <input type="text" class="form-input" id="coeficiente-tarifario"
                                    v-model="coeficienteTarifario" required>
                            </div>

                            <div class="mb-3 col-span-2 col-start-1 col-end-3">
                                <label class="form-label" for="setor-atuacao">Setor de Atuação:</label>
                                <input type="text" class="form-input" id="setor-atuacao" v-model="setorAtuacao"
                                    required>
                            </div>
                            <div class="mb-3 col-span-2 col-start-3 col-end-5">
                                <label class="form-label" for="tipo-servico">Tipo de Serviço:</label>
                                <input type="text" class="form-input" id="tipo-servico" v-model="tipoServico" required>
                            </div>

                            <div class="mb-3 col-span-2 col-start-1 col-end-3">
                                <label class="form-label" for="outorga-linha">Outorga da Linha:</label>
                                <input type="text" class="form-input" id="outorga-linha" v-model="outorgaLinha"
                                    required>
                            </div>
                            <div class="mb-3 col-span-2 col-start-3 col-end-5">
                                <label class="form-label" for="toalete">Toalete:</label>
                                <div class="flex h-10">
                                    <div class="flex w-1/2">
                                        <input class="h-full w-6 mr-1" v-model="toalete" name="toalete" value=1 id="toalete-sim" type="radio" />
                                        <label class="form-label flex items-center mr-5 mb-0" for="toalete">Sim</label>
                                    </div>
                                    <div class="flex w-1/2">
                                        <input class="h-full w-6 mr-1" v-model="toalete" name="toalete" value=0 id="toalete-nao" type="radio" />
                                        <label class="form-label flex items-center mr-5 mb-0" for="toalete">Não</label>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3 col-span-2 col-start-1 col-end-3">
                                <label class="form-label" for="inicio-contrato">Inicio de Contrato:</label>
                                <input type="text" class="form-input" id="inicio-contrato" v-model="inicioContrato"
                                    required>
                            </div>
                            <div class="mb-3 col-span-2 col-start-3 col-end-5">
                                <label class="form-label" for="fim-contrato">Fim de Contrato:</label>
                                <input type="text" class="form-input" id="fim-contrato" v-model="fimContrato" required>
                            </div>

                            <div class="mb-3 col-span-2 col-start-1 col-end-3">
                                <label class="form-label" for="inicio-operacao">Inicio da Operação:</label>
                                <input type="text" class="form-input" id="inicio-operacao" v-model="inicioOperacao"
                                    required>
                            </div>

                            <div class="mb-3 col-span-2 col-start-1 col-end-3">
                                <label class="form-label" for="patamarizada">Patamarizada:</label>
                                <div class="flex h-10">
                                    <div class="flex w-1/2">
                                        <input class="h-full w-6 mr-1" v-model="patamarizada" name="patamarizada" value=1 id="patamarizada-sim" type="radio" />
                                        <label class="form-label flex items-center mr-5 mb-0" for="patamarizada">Sim</label>
                                    </div>
                                    <div class="flex w-1/2">
                                        <input class="h-full w-6 mr-1" v-model="patamarizada" name="patamarizada" value=0 id="patamarizada-nao" type="radio" />
                                        <label class="form-label flex items-center mr-5 mb-0" for="patamarizada">Não</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>