<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import { hourFormat } from "@/core/services/helpers";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Multiselect from "vue-multiselect";

export default {
    components: {
        FontAwesomeIcon,
        Breadcrumbs,
        Multiselect
    },
    props: {
        id: { required: false },
    },
    data: () => {
        return {
            faTimesCircle,
            showListSchedule: true,
            orderOptions: [
                { id: 7, name: "Todos", label: " Todos" },
                { id: 1, name: "Segunda-feira", label: " Seg" },
                { id: 2, name: "Terça-feira", label: " Ter" },
                { id: 3, name: "Quarta-feira", label: " Qua" },
                { id: 4, name: "Quinta-feira", label: " Qui" },
                { id: 5, name: "Sexta-feira", label: " Sex" },
                { id: 6, name: "Sábado", label: " Sáb" },
                { id: 0, name: "Domingo", label: " Dom" }
            ],
            dayArrayAux: [
                { id: 1, name: "Segunda-feira", label: " Seg" },
                { id: 2, name: "Terça-feira", label: " Ter" },
                { id: 3, name: "Quarta-feira", label: " Qua" },
                { id: 4, name: "Quinta-feira", label: " Qui" },
                { id: 5, name: "Sexta-feira", label: " Sex" },
                { id: 6, name: "Sábado", label: " Sáb" },
                { id: 0, name: "Domingo", label: " Dom" }
            ],
            hourScheduleGo: 0,
            hourScheduleReturn: 0,
            dateWeekendGo: [],
            dateWeekendReturn: [],
            loader: false,
            loaderSearch: false,
            title: "Linhas",
            errors: false,
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Linhas", path: "lines.index" },
                { name: "Registro de horários", path: "lines.schedules" },
                { name: "Editar horário", path: "" },
            ],
            line: {
                line: "",
                name: "",
                itinerary: "",
                ordTravel: "",
                classification: "",
                landing: "",
                originPoint: {},
                destinyPoint: {},
                points: [],
                markers: [],
                schedules: [],
            },
            dayCounter: new Map([
                [1, [{tpdirecao: 1, count: 0}, {tpdirecao: 2, count: 0}]],
                [2, [{tpdirecao: 1, count: 0}, {tpdirecao: 2, count: 0}]],
                [3, [{tpdirecao: 1, count: 0}, {tpdirecao: 2, count: 0}]],
                [4, [{tpdirecao: 1, count: 0}, {tpdirecao: 2, count: 0}]],
                [5, [{tpdirecao: 1, count: 0}, {tpdirecao: 2, count: 0}]],
                [6, [{tpdirecao: 1, count: 0}, {tpdirecao: 2, count: 0}]],
                [0, [{tpdirecao: 1, count: 0}, {tpdirecao: 2, count: 0}]],
                ["origin", 0],
                ["destiny", 0],
            ]),
        };
    },
    async mounted() {
        if (this.id) {
            try {
                const response = await this.$axios.get("/api/lines/route/" + parseInt(this.id));

                this.line.nulinharamal = response.data.nulinharamal;
                this.line.nuextensao = response.data.nuextensao;
                this.line.name = response.data.name;
                this.line.deitinerario = response.data.deitinerario;
                this.line.points = response.data["points"];
                this.line.originPoint = response.data["origin_point"];
                this.line.destinyPoint = response.data["destiny_point"];
                this.line.schedules = response.data["line_schedules"];
                this.line.nmservicoveiculo = response.data["service_vehicle"]["nmservicoveiculo"];
                this.defineDayCounter();
                this.convertVariablesInMarks();
            } catch (e) {
                this.status = "rejected";
                this.$Progress.finish();
            }
        }
    },
    methods: {
        defineDayCounter() {
            this.dayCounter = new Map();
            let countGoing = 0;
            let countReturn = 0;
            this.dayArrayAux.forEach((day) => {
                let dayCounterGo = 0;
                let dayCounterReturn = 0;
                this.line.schedules.forEach((schedule) => {
                    if (schedule.nudiasemana == day.id && schedule.tpdirecao == 1) {
                        dayCounterGo++;
                    }
                    if (schedule.nudiasemana == day.id && schedule.tpdirecao == 2) {
                        dayCounterReturn++;
                    }
                });
                this.dayCounter.set(day.id, [{ tpdirecao: 1, count: dayCounterGo }, { tpdirecao: 2, count: dayCounterReturn }]);
                countGoing += dayCounterGo;
                countReturn += dayCounterReturn;
            });
            this.dayCounter.set('origin', countGoing);
            this.dayCounter.set('destiny', countReturn);
        },
        orderSchedule() {
            this.showListSchedule = false;

            for (let i = 0; i < this.line.schedules.length; i++) {
                let k = i;
                for (let j = i + 1; j < this.line.schedules.length; j++) {
                    if (this.line.schedules[j]["hrpartida"] && this.line.schedules[k]["hrpartida"]) {
                        if (this.line.schedules[j]["hrpartida"] < this.line.schedules[k]["hrpartida"])
                            k = j;
                    }
                }
                let tmp = {};
                tmp = this.line.schedules[i];
                this.line.schedules[i] = this.line.schedules[k];
                this.line.schedules[k] = tmp;
            }

            this.showListSchedule = true;
        },
        setHour(direction) {
            let dt = [];
            let hr = 0;

            if (direction === 1) {
                dt = this.dateWeekendGo;
                hr = this.hourScheduleGo;
            } else {
                dt = this.dateWeekendReturn;
                hr = this.hourScheduleReturn;
            }

            let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(hr);

            if (isValid) {
                if (dt.filter(dia => dia.id == 7).length > 0) {
                    dt = this.dayArrayAux;
                }

                let horarioDuplicado = [];
                let horarioDuplicadoSize = 0;
                dt.forEach(diaSelecionado => {
                    this.line.schedules.forEach(schedule => {
                        if (schedule.nudiasemana == diaSelecionado.id && schedule.tpdirecao == direction && (schedule.hrpartida === hr.concat(":00") || schedule.hrpartida === hr)) {
                            horarioDuplicado.push(diaSelecionado.label);
                        }
                    });
                    if (horarioDuplicadoSize < horarioDuplicado.length) {
                        horarioDuplicadoSize = horarioDuplicado.length;
                        return;
                    }
                    let schedule = {
                        hrpartida: hr,
                        nudiasemana: diaSelecionado.id,
                        nulinha: this.id,
                        tpdirecao: direction,
                        flsituacao: 1,
                        nuextensao: null,
                        nulinharamal: null,
                    };

                    this.showListSchedule = false;
                    this.line.schedules.push(schedule);
                    this.orderSchedule();

                    this.dayCounter.get(diaSelecionado.id)[direction - 1].count++;

                    if(direction === 1){
                        this.dayCounter.set('origin', this.dayCounter.get('origin') + 1);
                    } else {
                        this.dayCounter.set('destiny', this.dayCounter.get('destiny') + 1);
                    }
                });
                if (horarioDuplicado.length > 0) {
                    this.$toast.error({
                        title: "Erro",
                        message: "Horário já cadastrado para " + horarioDuplicado.toString(),
                    });
                }

                this.hourScheduleGo = "";
                this.hourScheduleReturn = "";
            }
        },
        deleteSchedule(id, day, direction) {
            this.showListSchedule = false;
            this.line.schedules.splice(id, 1);
            this.showListSchedule = true;

            this.dayCounter.get(day)[direction - 1].count--;
            if(direction === 1){
                this.dayCounter.set('origin', this.dayCounter.get('origin') - 1);
            } else {
                this.dayCounter.set('destiny', this.dayCounter.get('destiny') - 1);
            }
        },
        async updateSchedule() {
            try {
                this.$http.put("/api/lines/schedule/" + this.id, this.line).then((response) => {
                    this.$toast.success({
                        title: "OK",
                        message: response.data.success,
                    });
                    this.showModal = false;
                    this.$router.push({ name: "lines.schedules" });
                });
            } catch (err) {
                throw err;
            }
        },
        formatHour(time) {
            return hourFormat(time);
        },
        dateOfWeekend(dateW) {
            if (dateW == 1) return "Segunda-Feira";
            if (dateW == 2) return "Terça-Feira";
            if (dateW == 3) return "Quarta-Feira";
            if (dateW == 4) return "Quinta-Feira";
            if (dateW == 5) return "Sexta-Feira";
            if (dateW == 6) return "Sábado";
            if (dateW == 0) return "Domingo";
        },
        async save() {
            if (this.id) {
                this.updateSchedule();
            }
        },
        changeDiaDaSemanaGo() {
            if (this.dateWeekendGo.length > 0 && this.dateWeekendGo[0].name === 'Todos') {
                this.dateWeekendGo.shift();
            } else {
                if (this.dateWeekendGo.filter(dia => dia.name === 'Todos').length > 0) {
                    this.dateWeekendGo = [{ id: 7, name: "Todos", label: " Todos" }];
                }
            }
        },
        changeDiaDaSemanaReturn() {
            if (this.dateWeekendReturn.length > 0 && this.dateWeekendReturn[0].name === 'Todos') {
                this.dateWeekendReturn.shift();
            } else {
                if (this.dateWeekendReturn.filter(dia => dia.name === 'Todos').length > 0) {
                    this.dateWeekendReturn = [{ id: 7, name: "Todos", label: " Todos" }];
                }
            }
        },
    },
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs :crumbs="crumbs" />
            <form @submit.prevent="save" id="travel-form" class="flex flex-col space-y-4">
                <div class="w-full p-2">
                    <div class="flex flex-wrap justify-between mt-2 align-baseline border-b-2 border-blue-base">
                        <h1 class="pt-2 uppercase text-blue-base">Informações da Linha</h1>
                    </div>

                    <div
                        class="grid grid-flow-row gap-6 mt-5 text-xs md:grid-cols-2 lg:grid-cols-3 justify-content-between mb-15">
                        <div class="flex flex-col w-full">
                            <label class="form-label"> Origem </label>
                            <div>
                                {{ line.originPoint.nmlocalidade + " (" + line.originPoint.delocalidade + ")" }}
                            </div>
                        </div>
                        <div class="flex flex-col w-full">
                            <label class="form-label"> Destino </label>
                            <div>
                                {{ line.destinyPoint.nmlocalidade + " (" + line.destinyPoint.delocalidade + ")" }}
                            </div>
                        </div>
                        <div class="flex flex-col w-full">
                            <label class="form-label"> Número da Linha </label>
                            <div>
                                {{ line.nulinharamal + "-" + line.nuextensao }}
                            </div>
                        </div>
                        <div class="flex flex-col w-full">
                            <label class="form-label"> Tipo de Ônibus </label>
                            <div>
                                {{ line.nmservicoveiculo }}
                            </div>
                        </div>
                        <div class="flex flex-col w-full">
                            <label class="form-label"> Descrição do Itinerário </label>
                            <div>
                                {{ line.deitinerario }}
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-between mt-2 align-baseline border-b-2 border-blue-base">
                        <h1 class="pt-2 uppercase text-blue-base">Horários da Linha</h1>
                    </div>
                    <div
                        class="grid grid-flow-row gap-6 mt-5 text-xs md:grid-cols-2 lg:grid-cols-2 justify-content-between">
                        <div class="flex flex-col w-full items-center">
                            <label class="form-label"> Horários de {{ line.originPoint.nmlocalidade + " (" +
                                line.originPoint.delocalidade + ")" }} </label>
                            <p> Número de horários da semana: {{ dayCounter.get('origin') }}</p>

                            <div class="flex justify-content-between border w-full p-2">
                                <div class="flex justify-content-center items-center w-full">
                                    <Multiselect v-model="dateWeekendGo" :options="orderOptions" :multiple="true"
                                        :close-on-select="false" selectLabel="Clique para selecionar" class="w-2/3"
                                        selectedLabel="Selecionado" deselectLabel="Clique para remover" track-by="name"
                                        label="name" :clear-on-select="false" :preserve-search="true"
                                        placeholder="Dias da semana" :preselect-first="false" @select="changeDiaDaSemanaGo">
                                        <template #selection="{ values, isOpen }">
                                            <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
                                                {{ values.map(dia => dia.label).toString() }}
                                            </span>
                                        </template>
                                    </Multiselect>
                                    <input v-model="hourScheduleGo" style="padding: 7px 10px !important"
                                        class="pl-4 filter-search ml-2 mr-2 w-1/4  h-9" placeholder="Horário"
                                        type="time" />
                                    <div class="flex items-center justify-center w-32 ml-1 form-submit cursor-pointer"
                                        v-on:click="setHour(1)">
                                        Adicionar
                                    </div>
                                </div>
                            </div>

                            <div class="w-full items-center p-2">
                                <div class="flex flex-col w-full" v-if="showListSchedule">
                                    <template v-for="(day, index) in dayArrayAux">
                                        <div class="flex w-full border justify-content-between items-center mb-1"
                                            :key="index">
                                            <div class="w-1/3 p-2">
                                                {{ day.name }}
                                                <p>Núm. horários do dia: {{ dayCounter.get(day.id)[0].count }}</p>
                                            </div>
                                            <div class="w-2/3 p-2 flex flex-wrap">
                                                <div v-for="(schedule, i) in line.schedules" :key="i">
                                                    <div v-if="schedule.tpdirecao == 1 && schedule.nudiasemana == day.id"
                                                        class="flex items-center justify-content-center mr-2">
                                                        <div class="timer-clock-lines">
                                                            {{ formatHour(schedule.hrpartida) }}
                                                        </div>
                                                        <FontAwesomeIcon class="text-red-500 cursor-pointer"
                                                            :icon="faTimesCircle"
                                                            v-on:click="deleteSchedule(i, day.id, 1)">
                                                        </FontAwesomeIcon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col w-full items-center">
                            <label class="form-label"> Horários de {{ line.destinyPoint.nmlocalidade + " (" +
                                line.destinyPoint.delocalidade +
                                ")" }} </label>
                            <p> Número de horários da semana: {{ dayCounter.get('destiny') }}</p>

                            <div class="flex justify-content-between border w-full p-2">
                                <div class="flex justify-content-center w-full items-center">
                                    <Multiselect v-model="dateWeekendReturn" :options="orderOptions" :multiple="true"
                                        :close-on-select="false" selectLabel="Clique para selecionar" class="w-2/3"
                                        selectedLabel="Selecionado" deselectLabel="Clique para remover" track-by="name"
                                        label="name" :clear-on-select="false" :preserve-search="true"
                                        placeholder="Dias da semana" :preselect-first="false" @select="changeDiaDaSemanaReturn">
                                        <template #selection="{ values, isOpen }">
                                            <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
                                                {{ values.map(dia => dia.label).toString() }}
                                            </span>
                                        </template>
                                    </Multiselect>
                                    <input v-model="hourScheduleReturn" style="padding: 7px 10px !important"
                                        class="pl-4 filter-search ml-2 mr-2 w-1/4 h-9" placeholder="Horário"
                                        type="time" />
                                    <div class="flex items-center justify-center w-32 ml-1 form-submit cursor-pointer"
                                        v-on:click="setHour(2)">
                                        Adicionar
                                    </div>
                                </div>
                            </div>

                            <div class="w-full items-center p-2">
                                <div class="flex flex-col w-full" v-if="showListSchedule">
                                    <template v-for="(day, index) in dayArrayAux">
                                        <div class="flex w-full border justify-content-between items-center mb-1"
                                            :key="index">
                                            <div class="w-1/3 p-2">
                                                {{ day.name }}
                                                <p>Núm. horários do dia: {{ dayCounter.get(day.id)[1].count }}</p>
                                            </div>
                                            <div class="w-2/3 p-2 flex flex-wrap">
                                                <div v-for="(schedule, i) in line.schedules" :key="i">
                                                    <div v-if="schedule.tpdirecao == 2 && schedule.nudiasemana == day.id"
                                                        class="flex items-center justify-content-center mr-2">
                                                        <div class="timer-clock-lines">
                                                            {{ formatHour(schedule.hrpartida) }}
                                                        </div>
                                                        <FontAwesomeIcon class="text-red-500 cursor-pointer"
                                                            :icon="faTimesCircle"
                                                            v-on:click="deleteSchedule(i, day.id, 2)">
                                                        </FontAwesomeIcon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="submit" class="flex items-center justify-center py-2 mt-4 mb-2 form-submit md:w-auto">
                        <svg fill="currentColor" viewBox="0 0 20 20" class="w-4 h-4 mr-1">
                            <path fill-rule="evenodd"
                                d="M2 10a4 4 0 004 4h3v3a1 1 0 102 0v-3h3a4 4 0 000-8 4 4 0 00-8 0 4 4 0 00-4 4zm9 4H9V9.414l-1.293 1.293a1 1 0 01-1.414-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 9.414V14z"
                                clip-rule="evenodd"></path>
                        </svg>
                        Validar e Salvar
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
