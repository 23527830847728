<script>
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        selected: {
            type: Array,
            required: true,
            default: () => []
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Selecione'
        },
        optionalPlaceholderIcon: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            selection: [],
            auxId: Math.random().toString(36).substring(7)
        };
    },
    mounted() {
        this.selection = this.selected;
    },
    updated() {
        this.selection = this.selected;
    },
    methods: {
        onSelect() {
            this.$emit('update:selected', this.selection);
            this.$emit('changed', this.selection);
        },
        onRemove() {
            this.$emit('update:selected', this.selection);
        },
        clear() {
            this.selection = [];
        },
        addRotate180() {
            let caretElement = document.getElementById(this.auxId);
            caretElement.classList.add("rotate-180");
            caretElement.classList.remove("rotate");
        },
        removeRotate180() {
            this.$nextTick(() => {
                let caretElement = document.getElementById(this.auxId);
                caretElement.classList.remove("rotate-180");
                caretElement.classList.add("rotate");
            });
        },
    }
};
</script>
<style>
.outer-div {
    border-radius: 0.25rem;
    border: 1px solid #c7c7c7;
    width: 100%;
    height: 2.2rem;
}
.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 75%;
}

.personalized-multiselect>.multiselect__select,
.personalized-multiselect>.multiselect__tags {
    opacity: 0;
    max-height: 10px !important;
    overflow: hidden;
}

.rotate {
    transition: transform 0.2s cubic-bezier(0.62, 0.28, 0.23, 0.99);
    transform: rotate(0deg);
}

.rotate-180 {
    transition: transform 0.2s cubic-bezier(0.62, 0.28, 0.23, 0.99);
    transform: rotate(180deg);
}
</style>
<template>
    <div class="outer-div relative inline-block border-1px bg-white">
        <div class="flex items-center absolute z-0 top-2 left-3 w-11/12">
            <img class="h-4 mr-2" :src="require(`@/core/assets/images/${optionalPlaceholderIcon}`)">
            <span class="text-xs ellipsis">{{ selection.length > 0 ? selection.map(register => register.label).toString() : 'Dia da semana' }}</span>
        </div>
        <img :id="auxId" class="caret h-3 absolute top-3 left-11/12 rotate"
            :src="require(`@/core/assets/images/icon_caret.svg`)">
        <multiselect class="personalized-multiselect" v-model="selection" :options="options" :multiple="true" :close-on-select="false"
            selectLabel="Clique para selecionar" selectedLabel="Selecionado" deselectLabel="Clique para remover"
            track-by="name" label="name" :clear-on-select="false" :preserve-search="true" :placeholder="placeholder"
            :preselect-first="false" @select="onSelect" @remove="onRemove" @close="removeRotate180"
            @open="addRotate180">
        </multiselect>
    </div>
</template>