<script>
import SearchSelect from "@/core/components/SearchSelect";
import RegistryService from "@/core/services/RegistryService";
import Select from "@/core/components/Select";

export default {
    props: {
        service: {
            type: Object,
            required: true
        }
    },
    components: {
        Select,
        SearchSelect
    },
    data() {
        return {
            registryService: new RegistryService(),
            organization: {
                name: '',
                document: '',
                document_formatted: '',
                street: '',
                cep: '',
                number: '',
                complement: '',
                district: '',
                city: {
                    name: '',
                    state: {
                        initials: ''
                    }
                },
                emails: [
                    {
                        contact: ''
                    }
                ],
                phones: [
                    {
                        contact: ''
                    }
                ]
            },
            user: {
                name: '',
                emails: [
                    {
                        contact: ''
                    }
                ],
                document: '',
                cargo: ''

            },
            requirement: {
                link: null,
                line: null,
                especification: '',
                justification: '',
                service: null,
                process: null,
                additionalInfo: []
            },
            auxSelectedService: null,
            auxAdditionalInfo: null,
            anexo: null,
        };
    },
    mounted() {
        try {
            this.user = this.$store.state.auth.user;
            this.requirement.link = this.$store.state.auth.link;

            this.registryService.getUserInfo(this.$store.state.auth.link.organization.document).then(response => {
                this.organization = response;
            });
        } catch (error) {
            this.$toast.error({
                message: "Ocorreu um erro ao selecionar a sua organização. Por favor, tente novamente.",
            });
        }
    },
    methods: {
        isNullOrEmpty(value) {
            return value === null || value === undefined || value === '' || value.length === 0;
        },
        validateForm() {
            if (this.isNullOrEmpty(this.requirement.line)) {
                this.$toast.error({
                    title: "Erro",
                    message: "Selecione uma linha"
                });
                return false;
            }
            if (this.isNullOrEmpty(this.requirement.especification.trim())) {
                this.$toast.error({
                    title: "Erro",
                    message: "Preencha a especificação do pedido"
                });
                return false;
            }
            if (this.isNullOrEmpty(this.requirement.justification.trim())) {
                this.$toast.error({
                    title: "Erro",
                    message: "Preencha a justificativa do pedido"
                });
                return false;
            }
            if (this.service.subitens.length > 0 && this.requirement.service == null) {
                this.$toast.error({
                    title: "Erro",
                    message: "Selecione um tipo de serviço"
                });
                return false;
            }
            if (this.requirement.service.additionalFields != undefined &&
                this.requirement.service.additionalFields.length > 0) {
                for (let additionalField of this.requirement.service.additionalFields) {
                    let value = this.requirement.additionalInfo.filter(item => {
                        if (item.name == additionalField.label) {
                            return item;
                        }
                    });
                    if (this.isNullOrEmpty(value)) {
                        this.$toast.error({
                            title: "Erro",
                            message: `Preencha o campo ${additionalField.label}`
                        });
                        return false;
                    }
                }
            }
            if (this.requirement.line.nutransportadora != this.requirement.link.organization.id) {
                this.$toast.error({
                    title: "Erro",
                    message: "A linha selecionada não pertence a sua organização"
                });
                return false;
            }
            return true;
        },
        submitForm() {
            if (this.service.subitens.length == 0) {
                this.requirement.service = this.service;
            }

            if (!this.validateForm()) {
                return;
            };

            this.$Progress.start();
            // requisição para cadastro do processo
            this.$axios.post("/api/protocol/process", {
                alias: this.requirement.service.alias,
                organization: this.$store.state.auth.link.organization.id,
                objects: [
                    {
                        id: this.requirement.line.idlinha,
                        type: "lines"
                    }
                ]
            }).then(response => {
                this.requirement.process = response.data.process;
                this.createRequirement();
            }).catch(e => {
                this.$Progress.finish();
                this.$toast.error({
                    title: "",
                    message:
                        e.response.data.message || e.response.data.error
                });
            });
        },
        createRequirement() {
            // requisição para criar requerimento e anexar no processo
            this.$axios.post("/api/lines/line/line-requirement", this.requirement, {
                dataType: "binary",
                processData: false,
                responseType: "arraybuffer"
            }).then(response => {
                let blob = new Blob([response["data"]], {
                    type: response.headers["content-type"]
                });
                this.attachRequirement(this.requirement.process, blob);
            }).catch(() => {
                this.$Progress.finish();
                this.$toast.error({
                    title: "",
                    message:
                        "Ocorreu um erro ao gerar o requerimento."
                });
            });
        },
        attachRequirement(process, blob) {
            let attachment = new File([blob], "requerimento.pdf", {
                type: "application/pdf"
            });

            let formData = new FormData();

            formData.append("document", attachment);
            // document_type_id 27 = Requerimento
            formData.append("document_type_id", 27);
            formData.append("process_id", process);

            this.$axios.post("/api/protocol/document", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(() => {
                this.$Progress.finish();
                this.$toast.success({
                    title: "OK",
                    message: "Protocolo criado e requerimento anexado ao processo."
                });
                this.$router.push({
                    name: "process.show",
                    params: { id: process }
                });
            }).catch(e => {
                this.$Progress.finish();
                this.$toast.error({
                    title: "",
                    message:
                        e.response.data.message || e.response.data.error
                    // "Ocorreu algum erro ao anexar o requerimento ao processo."
                });
            });
        },
        goBack() {
            this.$router.go(-1);
        },
        handleFileChange(event) {
            this.anexo = event.target.files[0];
        },
        changeLine(line) {
            this.requirement.line = line;
        },
        changeSelectedService(service) {
            this.requirement.additionalInfo = [];
            this.requirement.service = this.service.subitens.filter(item => item.id == service)[0];
        },
        changeAdditionalInfo(event, label) {
            if (event.target) {
                let value = event.target.value;
                if (event.target.value.length == 0) {
                    value = 0;
                }

                let done = false;
                this.requirement.additionalInfo.map(item => {
                    if (item.name == label) {
                        done = true;
                        item.value = value
                    }
                });

                if (!done) {
                    this.requirement.additionalInfo.push({ name: label, value: value })
                }

            } else {
                // se select
                this.requirement.additionalInfo = [];
                let value = this.requirement.service.additionalFields.filter(item => item.label == label)[0].options.filter(item => item.id == event)[0];
                this.requirement.additionalInfo.push({ name: label, value: value.name })
            }
        }
    }
};
</script>

<template>
    <div class="px-4">
        <div class=" px-4">
            <div class="px-4 bg-white shadow-md pt-3 pb-10 text-sm">
                <form @submit.prevent="submitForm" class="grid grid-cols-3 gap-4">
                    <div class="mb-3">
                        <label class="form-label" for="linha">Linha:</label>
                        <div class="">
                            <SearchSelect :selected.sync="requirement.line" :search-keys="[]" :search-remote="true"
                                :lazy-load="true" options-url="/api/lines/route/filter/"
                                :digits-before-search="requirement.link.organization.id == 0 ? 1 : 0" option-key="linha"
                                field-class="py-2 h-10" name="nulinharamal" @changed="changeLine"
                                :option-value="linha => linha.nulinharamal + ' - ' + linha.nuextensao">
                            </SearchSelect>
                        </div>
                    </div>
                    <div v-if="service.subitens.length > 0">
                        <label class="form-label" for="specific-process-type">Tipo: </label>
                        <Select class="w-full" divClass="h-10" selectClass="h-full form-select filter-select"
                            :options="service.subitens" :selected.sync="auxSelectedService"
                            @changed="changeSelectedService" />
                    </div>
                    <template v-if="requirement.service != null && requirement.service.additionalFields != undefined">
                        <div class="">
                            <div class="grid"
                                :class="[requirement.service.additionalFields.length == 1 ? '' : `gap-4 grid-cols-${requirement.service.additionalFields.length}`]">
                                <template v-for="additionalField in requirement.service.additionalFields">
                                    <div class="" :key="additionalField.name">
                                        <label class="form-label" :for="additionalField.name">{{ additionalField.label
                                            }}:</label>
                                        <template v-if="additionalField.type == 'number'">
                                            <input class="form-input h-10" :id="additionalField.name" min="0"
                                                pattern="[0-9]+([,][0-9]+)?"
                                                v-on:change="event => changeAdditionalInfo(event, additionalField.label)">
                                        </template>
                                        <template v-else-if="additionalField.type == 'select'">
                                            <Select class="w-full" divClass="h-10"
                                                selectClass="h-full form-select filter-select"
                                                :options="additionalField.options" :selected.sync="auxAdditionalInfo"
                                                @changed="event => changeAdditionalInfo(event, additionalField.label)" />
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                    <div class="col-span-3">
                        <label class="form-label" for="especificacao">Especificação do pedido:</label>
                        <textarea class="form-input" id="especificacao" v-model="requirement.especification"></textarea>
                    </div>
                    <div class="mb-3 col-span-3">
                        <label class="form-label" for="justificativa">Justificativa do Pedido:</label>
                        <textarea class="form-input" id="justificativa" v-model="requirement.justification"></textarea>
                    </div>
                    <!-- <div class="mb-3">
                        <label class="form-label" for="anexo">Anexo:</label>
                        <input class="form-input w-3/12" type="file" id="anexo" @change="handleFileChange">
                    </div> -->
                    <div class="flex gap-3 mt-4">
                        <button class="h-9 form-submit" type="submit">Enviar</button>
                        <button class="w-full rounded-sm btn-gray text-xs uppercase" @click="goBack">Voltar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>