import Index from "./views/Index";
import CreateRegularLine from "@/domains/lines/views/CreateRegularLine";
import CreatePointSession from "@/domains/lines/views/geo/CreatePointSession";
import EditSchedule from "@/domains/lines/views/schedules/EditSchedule";
import SearchLines from "@/domains/lines/views/search/SearchLines";
import ListLines from "@/domains/lines/views/search/ListLines";
import Services from "@/domains/lines/views/services/Services";
import CreateService from "@/domains/lines/views/services/CreateService.vue";

export default [
    {
        path: "/linhas",
        name: "lines.index",
        component: Index,
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/linhas-regulares/criar",
        name: "lines.regular.create",
        component: CreateRegularLine,
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/sessões",
        name: "lines.sessions",
        component: () =>
            import(
                /* webpackChunkName: "chunk-lines" */ "./views/geo/Sessions.vue"
            ),
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/sessões/criar",
        name: "lines.sessions.create",
        component: () =>
            import(
                /* webpackChunkName: "chunk-lines" */ "./views/geo/CreatePointSession.vue"
            ),
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/sessões/:id",
        name: "lines.sessions.edit",
        component: CreatePointSession,
        props: true,
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/horarios",
        name: "lines.schedules",
        component: () =>
            import(
                /* webpackChunkName: "chunk-lines" */ "./views/schedules/Schedules.vue"
            ),
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/horarios/criar",
        name: "lines.schedules.create",
        component: () =>
            import(
                /* webpackChunkName: "chunk-lines" */ "./views/schedules/EditSchedule.vue"
            ),
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/horarios/:id",
        name: "lines.schedules.edit",
        component: EditSchedule,
        props: true,
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/horarios/:id",
        name: "manager.lines.show",
        component: EditSchedule,
        props: true,
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/consulta",
        name: "lines.search.search",
        component: SearchLines,
        props: true,
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/horarios",
        name: "lines.search.search",
        component: SearchLines,
        props: true,
        meta: {
            hideSidebar: true,
            forAuth: false,
            module: "lines"
        }
    },
    {
        path: "/linhas/consulta/:origin/:destiny",
        name: "lines.search.list",
        component: ListLines,
        props: true,
        meta: {
            forAuth: false,
            module: "lines"
        }
    },
    // {
    //     path: "/linhas/coeficiente-tarifario",
    //     name: "lines.tariffCoefficient",
    //     component: TariffCoefficient,
    //     props: true,
    //     meta: {
    //         forAuth: true,
    //         module: "lines"
    //     }
    // },
    // {
    //     path: "/linhas/coeficiente-tarifario/:id(\\d+)",
    //     name: "lines.tariffCoefficient.view",
    //     component: ViewTariffCoefficient,
    //     props: true,
    //     meta: {
    //         forAuth: true,
    //         module: "lines"
    //     }
    // },
    // {
    //     path: "/linhas/coeficiente-tarifario/criar",
    //     name: "lines.tariffCoefficient.create",
    //     component: CreateTariffCoefficient,
    //     props: true,
    //     meta: {
    //         forAuth: true,
    //         module: "lines"
    //     }
    // },
    {
        path: "/linhas/servicos",
        name: "lines.services",
        component: Services,
        props: true,
        meta: {
            forAuth: true,
            module: "lines"
        }
    },
    {
        path: "/linhas/servicos/criar",
        name: "lines.services.create",
        component: CreateService,
        props: true,
        meta: {
            forAuth: true,
            module: "lines"
        }
    }
];
